header{
    background-color: #0f0e0cde;
    box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.801);
    display: grid;
    grid-template-columns: 1fr 3fr;
    position: fixed;
    width: 100vw;
    height: 102px;
    top: 0;
    z-index: 5;
    backdrop-filter: blur(10px);
}
header>.logo{
    width: 300px;
    align-self: center;
}

a{
    color: #A38454;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 2.5px 12px 5px 12px;
    border-radius: 25px;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
nav a.active{
    background: rgba(0, 0, 0, 0.486);
}
div>span{
    color: #ECEDEA;
    font-size: 1.3rem;
    margin-left: 5px;
}
a>svg{
    margin-bottom: -2px;
}

.navmenu a div{
    display: flex;
    align-items: center;
}

.menu-btn{
    display: none;
    background-color: transparent;
    padding: 0  ;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.close-btn{
    display: none;
}
button{
    background-color: transparent;
    color: #ECEDEA;
    font-size: 1.5rem;
    display: none;
}

@keyframes closeMenu {
    to{
        right: -100%;
    }
}


/*===================== Responsive for Medium ====================*/
@media screen and (max-width: 1024px){
    header{
        display: flex;
        flex-direction: column;
        height: 130px;
    }
    header>.logo{
        width: 250px;
    }
}

/*===================== Responsive for Small ====================*/
@media screen and (max-width: 550px){
    button{
        display: block;
    }

    header{
        height: max-content;
    }

    .close-btn{
        cursor: pointer;
        
        display: block;
        color: #ECEDEA;
        position: absolute;
        top: .5rem;
        right: 1.5rem;
    }

    header>.menu-btn>button{
        font-size: 2.3rem;
    }

    .menu-btn{
        margin-top: 13px;
        margin-right: 10px;
    }

    header>.logo{
        width: 200px;
        align-self: flex-start;
    }

    .menu-btn{
        display: block;
    }

    nav{
        top: 1rem;
        align-items: flex-start;
        gap: 2rem;
        flex-direction: column;
        position: absolute;
        backdrop-filter: blur(15px);
        padding: 1rem;
        border-radius: 10px;
        z-index: 1;
        animation: closeMenu 500ms ease-in-out forwards;
        right: -100%;
    }

    .navmenu{
        background-color: #2b2b28f5;
        backdrop-filter: blur(10px);
    }

    @keyframes showMenu {
        to{
            right: 0;
        }
    }
}