.scroll{
    background-color: #0f0e0cb6;
    backdrop-filter: blur(10px);
    padding: 5px 5px;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    display: flex;
    gap: 1.5rem;
    border-radius: 3rem;
    color: #ECEDEA;
}

.scroll>button{
    display: block;
}

.prev,.next{
    color: #ECEDEA;
    transition: 300ms ease-in-out;
    border-radius: 5rem;
    padding: 3px;
    height: 22px;
    cursor: pointer;
    font-size: 1rem;
}

.prev:hover,.next:hover{
    background-color: #0f0e0c9d;
}
