#home{
    display: flex;
    flex-direction: row;
}

#home.section{
    padding-top: 150px;
}

.carousel>img{
    width: 500px;
    border-radius: 15px;
}

.right-side>p{
    padding: 0  ;
    text-indent: 0;
}

.right-side{
    padding: 30px;
}

.homeinfo{
    text-align: center;
}

.homeinfo>h1{
    font-size: 2rem;
    font-weight: bolder;
}

.homeinfo>h2{
    color: var(--color-primary);
    font-size: 1.2rem;
    padding: 0 15px;
}

.homeinfo>p{
    font-size: 1.3rem;
}

.right-side>a>span{
    color: #ECEDEA;
}

.right-side>a{
    background-color: rgba(0, 0, 0, 0.719);
    width: max-content;
    padding: 15px;
    border-radius: 50px;
    margin: auto;
    margin-top: 20px;
}

.right-side>a:hover{
    background-color: rgba(0, 0, 0, 0.589);
}

/* define as dimensões do slideshow */
.carousel {
    width: 500px;
    height: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
  }
  
  /* define as dimensões dos slides e alinha-os horizontalmente */
  .slide {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 500px;
    animation: slide 20s infinite;
  }

  .slides>img {
    width: 20%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  /* define a animação de transição */
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    15% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-100%);
    }
    45% {
      transform: translateX(-200%);
    }
    60% {
      transform: translateX(-300%);
    }

    75% {
        transform: translateX(-400%);
    }

  }
/*===================== Responsive for Medium ====================*/
@media screen and (max-width: 1024px){
    #home{
        flex-direction: column;
    }
    .carousel{
        width: 100%;
    }
    #home.section{
        padding-top: 130px;
    }
}

/*===================== Responsive for Small ====================*/
@media screen and (max-width: 500px){
    #home.section{
        padding-top: 60px;
    }

    .carousel{
        height: 300px;
    }

    .right-side{
        padding: .5rem;
    }
}